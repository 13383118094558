@import "../../SiteShared.scss";
@import "../../../src/styles/variables";

// Global
.add-lead-modal {
  background: red !important;
}
.semanticModal {
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  overflow-y: auto;
  &-request {
    &-header {
      background: $accent-color !important;
    }
    &-footer {
      background: $accent-color-light !important;
    }
    &-submit {
      background: $accent-color !important;
      color: $white !important;
    }
    &-close {
      background: $primary-color !important;
      color: $white !important;
    }
  }
  .imageviewer {
    // .view-photo-content {
    width: 500px;
    height: 500px;
    margin: auto;
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
    }
    // }
  }
}
.semanticModalPay {
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  overflow-y: hide;
  &-request {
    &-header {
      background: $accent-color !important;
    }
    &-footer {
      background: $accent-color-light !important;
    }
    &-submit {
      background: $accent-color !important;
      color: $white !important;
    }
    &-close {
      background: $primary-color !important;
      color: $white !important;
    }
  }
}

#unitFormInput,
#priorityFormInput,
#categoryFormInput {
  border-color: $accent-color !important;
}

// #unitFormInput:active,
// #unitFormInput:focus,
// #unitFormInput:checked,
// #priorityFormInput:focus,
// #categoryFormInput:focus{
//   background:#FFFFFF !important;
// }

// .error {
// }

// maintenance css
.maintenance-wrapper {
  .header-rw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header {
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 0px;
      text-align: left !important;
      color: #171a1d;
      font-family: "Be Vietnam", sans-serif !important;
    }
    p {
      font-size: 13px;
      font-weight: normal;
      color: #5e6267;
    }
    .right-top-head {
      input {
        border-radius: 3px;
        box-shadow: 0 11px 14px -10px #e5eced;
        background-color: #ffffff;
        border: 0px;
        height: 45px;
        margin-right: 20px;
        font-size: 13px;
        font-weight: normal;
        width: 230px;
        box-sizing: border-box;
        color: rgba(23, 26, 29, 0.7);
      }
      button {
        &.ui.button {
          background-image: linear-gradient(100deg, #3b1c5a, #374db1 163%);
          height: 45px;
          color: #fff;
          width: 250px;
          padding: 0px !important;
          box-shadow: unset;
          border-radius: 3px !important;
        }
      }
    }
  }
  .maintenance-listing {
    margin-top: 51px;
    .menu {
      width: 210px;
      height: 145px;
      padding: 20px 0;
      border-radius: 3px;
      box-shadow: 0 11px 14px -10px #e5eced;
      background-color: #ffffff;
      right: 4px;
      z-index: 999 !important;
      position: absolute !important;
      left: unset !important;
      border: 0px;
      top: 27px;
      .item {
        background-repeat: no-repeat;
        background-position: center left 16px;
        padding-left: 45px !important;
        &.cancel-txt {
          background-image: url("/assets/img/icons-close-red.svg");
          background-repeat: no-repeat;
          background-position: center left 16px;
          .text {
            color: #ef2c2c;
          }
        }
        &.customer-txt {
          background-image: url("/assets/img/icons-phone.svg");
          background-repeat: no-repeat;
          background-position: center left 16px;
        }
        &.contact-txt {
          background-image: url("/assets/img/icons-feedback.svg");
          background-repeat: no-repeat;
          background-position: center left 16px;
        }
      }
      .text {
        font-size: 13px;
        font-weight: normal;
        color: #5e6267;
      }
    }
    .ui.card {
      width: 100%;
      border-radius: 3px;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1) !important;
      .content {
        padding: 20px;
        .card-header {
          padding: 0px;
          margin: 0px;
          border: 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .ellipsis {
            margin: 0px !important;
            &.vertical:after {
              display: none;
            }
          }
          h5 {
            font-size: 16px !important;
            font-weight: 500 !important;
            margin-bottom: 0px;
            color: #171a1d;
            text-transform: capitalize;
          }
          h6 {
            font-size: 13px;
            font-weight: 500 !important;
            color: #5e6267;
            text-transform: capitalize;
            margin-bottom: 0px;
          }
          .action-btn {
            width: 80px;
            height: 25px;
            border-radius: 15px;
            font-size: 11px;
            font-weight: bold;
            color: #fff;
            display: inline-block;
            background-color: #11ae7d;
            text-align: center;
            line-height: 25px;
            &.resolved-btn {
              background-color: #171a1d;
            }
            &.cancelled-btn {
              background-color: #ef2c2c;
            }
          }
          .ellipsis {
            border-radius: 3px;
            background-color: rgba(59, 28, 90, 0.05);
            width: 25px;
            height: 25px;
            display: inline-block;
            margin-left: 5px;
            line-height: 25px;
            cursor: pointer;
            font-size: 12px;
            color: #5e6267;
            font-weight: unset;
          }
        }
      }
      .description {
        font-size: 14px;
        font-weight: normal;
        margin-top: 20px;
        line-height: 26px;
        cursor: pointer;
      }
    }
  }
}

.left-head {
  .page-back {
    width: 45px;
    height: 45px;
    padding: 10px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-right: 15px;
    cursor: pointer;
  }
}

.right-maintenance {
  position: absolute;
  display: flex;
  right: 0px;
  top: 32px;
}

.item.btn.btn-secondary .text {
  height: 40px;
  width: 220% !important;
  display: inline-block;
  margin-left: 20px;
  border-radius: 3px;
  background-color: #0b7755 !important;
  font-size: 13px;
  color: #fff !important;
  line-height: 35px;
  font-family: "Be Vietnam", sans-serif !important;
}

.dropdown-icon {
  right: 0;
  margin-right: 55px;
  margin-top: -110px;
  position: absolute;
  z-index: 10;
  height: 5px;
  .ellipsis {
    padding: 10px;
  }
}

// description modal
.desc-detail-modal {
  .view-photo-content {
    width: 500px;
    height: 500px;
    margin: auto;
    .photo {
      width: auto !important;
      height: auto !important;
      object-fit: contain;
    }
  }

  .modal-content {
    width: 340px;
    margin: 8vh auto;
    border-radius: 5px;
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #ffffff;
    .modal-header {
      padding: 15px 0;
      background: unset;
      border: 0px;
      button {
        padding: 8px 15px;
        span {
          color: #6d6d6d;
          font-size: 24px;
        }
      }
    }
    .modal-body {
      padding: 0px;
      .data-rw {
        padding: 15px 40px;
        border-bottom: 1px solid rgba(94, 98, 103, 0.1);
        display: flex;
        justify-content: space-between;
        .list {
          display: flex;
          flex-direction: column;
          h5 {
            font-size: 15px !important;
            font-weight: 600 !important;
            color: #171a1d;
            margin-bottom: 0px;
            color: #171a1d;
            margin-bottom: 0px;
          }
          h6 {
            font-size: 13px;
            font-weight: normal;
            color: rgba(23, 26, 29, 0.7);
            margin-bottom: 0px;
          }
          label {
            font-size: 12px;
            font-weight: normal;
            color: #5e6267;
            font-family: "Be Vietnam", sans-serif !important;
            margin-bottom: 0px;
          }
          span {
            font-size: 13px;
            font-weight: normal;
            color: #171a1d;
            line-height: 21px;
            &.action-btn {
              width: 80px;
              height: 25px;
              border-radius: 15px;
              font-size: 11px;
              font-weight: bold;
              color: #fff;
              display: inline-block;
              background-color: #11ae7d;
              text-align: center;
              line-height: 25px;
              text-transform: uppercase;
            }
          }
        }
      }
      .view-link {
        font-size: 12px;
        font-weight: 500;
        padding: 15px 40px;
        display: block;
        color: #3b1c5a;
        text-decoration: underline;
        text-transform: capitalize;
      }
    }
    .modal-footer {
      background: unset;
      padding-top: 10px;
      border: 0px;
      padding-bottom: 30px;
      button {
        height: 45px;
        width: 100%;
        margin: 0px 20px;
        border-radius: 3px;
        background-color: #6d6d6d !important;
        font-size: 13px;
        font-weight: 800;
        color: #fff !important;
        line-height: 45px;
        font-family: "Be Vietnam", sans-serif !important;
      }
    }
  }
}
#descphotomodal {
  .view-photo-content {
    height: 100%;
    .photo-viewer-container {
      height: 100% !important;
    }
  }
}
// maintenance request modal
.maintenance-request-modal {
  width: 500px !important;
  border-radius: 6px !important;
  background-color: #f8f8f8 !important;
  .header {
    background: unset !important;
    padding: 50px 20px 20px 50px !important;
    border: 0px;
    position: relative;
    border-bottom: 0px !important;
    img {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
    .header {
      font-size: 22px;
      font-weight: 800;
      color: #171a1d;
      margin-top: 0px;
      padding: 0px !important;
      font-family: "Be Vietnam", sans-serif !important;
      text-align: left !important;
    }
  }
  .ui.attached {
    box-shadow: unset !important;
    border-radius: 3px !important;
    background-color: rgba(94, 98, 103, 0.1);
    margin: 0px 50px;
    font-size: 11px;
    font-weight: normal;
    line-height: 16px;
    color: #171a1d;
  }
  .content {
    margin: 30px 50px 5px 50px;
    width: auto !important;
    background: unset !important;
    padding: 0px !important;
  }
  form {
    .form-rw {
      position: relative;
      label {
        font-size: 13px;
        font-weight: 500;
        color: #5e6267;
        font-family: "Be Vietnam", sans-serif !important;
        position: absolute;
        top: 15px;
        z-index: 9;
        left: 20px;
      }
      .text {
        height: 100%;
        line-height: 55px;
      }
      .dropdown.icon {
        height: 100% !important;
        line-height: 55px !important;
      }
      textarea {
        border: 0px;
        border-radius: 3px;
        box-shadow: 0 11px 14px -10px #e5eced;
        resize: none;
        padding-top: 35px;
        padding-left: 20px;
        padding-right: 20px;
      }
      &.textarea-rw {
        margin-top: 20px;
      }
    }
    .ui.dropdown {
      border-radius: 3px;
      box-shadow: 0 11px 14px -10px #e5eced;
      background-color: #ffffff;
      height: 75px;
      border: 0px;
      padding: 15px 20px;
      .default.text {
        font-size: 13px;
        font-weight: 500;
        color: #5e6267;
      }
    }
    .ui.checkbox {
      margin-top: 6px;
      margin-bottom: 25px;
      input {
        margin-bottom: 0px;
      }
      label {
        font-size: 13px;
        color: #5e6267;
        font-weight: 500;
        font-family: "Be Vietnam", sans-serif !important;
        padding-left: 30px;
        margin-bottom: 0px;
        &:before {
          top: 6px;
          background: unset;
          box-shadow: none;
          width: 17px;
          height: 11px;
          border: 1px solid #5e6267;
        }
        &:after {
          box-shadow: unset;
          width: 5px;
          height: 5px;
          top: 9px;
          left: 3px;
          border-radius: 50%;
          background: #5e6267;
        }
        &:hover {
          &::before {
            background-color: #ffffff;
            border: 1px solid #5e6267;
          }
        }
      }
      input:checked ~ label:before {
        background: linear-gradient(100deg, #3b1c5a, #374db1 163%);
      }
      input:checked ~ label:after {
        left: 10px;
        box-shadow: unset;
        background: #ffffff;
      }
    }
    .image-click {
      position: absolute;
      opacity: 0;
      .input {
        background: red !important;
      }
    }

    .image-upload {
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.05);
      height: 60px;
      display: flex;
      align-items: center;
      border: 0px;
      padding: 20px;
      span {
        font-size: 12px;
        font-weight: normal;
        color: #5e6267;
        margin-left: 10px;
      }
    }
  }
  .semanticModal-request-footer {
    background: unset !important;
    border-top: 0px !important;
    padding: 0px 50px 30px !important;
    .ui.buttons {
      width: 100%;
      button.button {
        height: 45px;
        border-radius: 3px;
        background-image: linear-gradient(
          96deg,
          #3b1c5a,
          #374db1 163%
        ) !important;
        box-shadow: unset;
        font-family: "Be Vietnam", sans-serif !important;
        width: 100%;
        opacity: 1 !important;
      }
      button.button:disabled {
        opacity: 0.5 !important;
      }
    }
  }
}
